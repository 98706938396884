import cx from 'classnames'
import { AnimatePresence, motion } from 'motion/react'
import { useMemo } from 'react'

import {
  type SanityProductGalleryPhoto,
  type SanityProductListingPhoto,
  type SanityProductVariantFragment,
} from '@data/sanity/queries/types/product'
import { thumbAnimation } from '@lib/animate'
import { hasObject } from '@lib/helpers'
import { getProductListingThumbnail } from '@lib/product-images'

import Photo from '@components/photo'

interface ProductThumbnailProps {
  galleryPhotos?: SanityProductGalleryPhoto[]
  listingPhotos?: SanityProductListingPhoto[]
  activeVariant: SanityProductVariantFragment
}

const ProductThumbnail = ({
  galleryPhotos,
  listingPhotos,
  activeVariant,
}: ProductThumbnailProps) => {
  const listingPhoto = useMemo(() => {
    const activeVariantThumbnail = listingPhotos?.find((listingPhoto) => {
      if (!listingPhoto.forOption) {
        return false
      }

      const option = {
        name: listingPhoto.forOption.split(':')[0],
        value: listingPhoto.forOption.split(':')[1],
      }

      return !!option.value && hasObject(activeVariant.options, option)
    })

    if (activeVariantThumbnail) {
      return activeVariantThumbnail
    }

    const defaultThumbnail = listingPhotos?.find(
      (listingPhoto) => !listingPhoto.forOption
    )

    return defaultThumbnail
  }, [activeVariant.options, listingPhotos])

  if (!listingPhoto) {
    return null
  }

  const thumbnailImage = getProductListingThumbnail(
    galleryPhotos ?? [],
    listingPhoto.forOption,
    listingPhoto.galleryImage
  )
  const thumbnailHoverImage = getProductListingThumbnail(
    galleryPhotos ?? [],
    listingPhoto.forOption,
    listingPhoto.galleryImageHover
  )

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={`${listingPhoto.galleryImage ?? ''}${
          listingPhoto.galleryImageHover ?? ''
        }`}
        initial="hide"
        animate="show"
        exit="hide"
        variants={thumbAnimation}
        className="relative overflow-hidden"
      >
        {(!!thumbnailImage || !!thumbnailHoverImage) && (
          <>
            {!!thumbnailImage && (
              <Photo
                image={{ ...thumbnailImage, customRatio: 5 / 7 }}
                sizes="(min-width: 1200px) 33vw, (min-width: 768px) 50vw, 100vw"
                imageClassName="h-full object-cover"
                className="h-full"
                fadeIn
              />
            )}

            {!!thumbnailHoverImage && (
              <Photo
                image={{ ...thumbnailHoverImage, customRatio: 5 / 7 }}
                sizes="(min-width: 1200px) 33vw, (min-width: 768px) 50vw, 100vw"
                imageClassName="h-full object-cover"
                className={cx(
                  'h-full absolute inset-0 z-10 opacity-0 invisible transition-all',
                  'group-hover:opacity-100 group-hover:visible'
                )}
                fadeIn
              />
            )}
          </>
        )}

        {!thumbnailImage && !thumbnailHoverImage && (
          <div className="pt-[calc((7/5)*100%)]">{/* TODO: Placeholder */}</div>
        )}
      </motion.div>
    </AnimatePresence>
  )
}

export default ProductThumbnail
