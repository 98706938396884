import Cookies from 'js-cookie'
import { AnimatePresence, motion } from 'motion/react'
import { Fragment, useContext, useEffect, useRef, useState } from 'react'

import { type SanityCookieSettings } from '@data/sanity/queries/types/site'
import { barAnimation } from '@lib/animate'
import { StringsContext } from '@lib/strings-context'

import Button, { ButtonVariant } from '@components/buttons/button'
import CustomLink from '@components/link'

interface AcceptCookies {
  isLoading: boolean
  acceptedCookies: boolean
  onAcceptCookies: () => void
}

interface CookieBarProps {
  cookieSettings?: SanityCookieSettings
}

const useAcceptCookies = (cookieName = 'accept_cookies'): AcceptCookies => {
  const isLoading = useRef(true)
  const [acceptedCookies, setAcceptedCookies] = useState(true)

  useEffect(() => {
    if (!Cookies.get(cookieName)) {
      setAcceptedCookies(false)
    }

    isLoading.current = false
  }, [cookieName])

  return {
    isLoading: isLoading.current,
    acceptedCookies,
    onAcceptCookies: () => {
      setAcceptedCookies(true)
      Cookies.set(cookieName, 'accepted', {
        expires: 365,
      })
    },
  }
}

const CookieBar = ({ cookieSettings }: CookieBarProps) => {
  const strings = useContext(StringsContext)

  const { isLoading, acceptedCookies, onAcceptCookies } = useAcceptCookies()

  const enabled = cookieSettings?.enabled
  const message = cookieSettings?.message
  const link = cookieSettings?.link

  if (!enabled || !message || acceptedCookies || isLoading) {
    return null
  }

  return (
    <AnimatePresence>
      <motion.div
        initial="show"
        animate="show"
        exit="hide"
        variants={barAnimation}
        role="dialog"
        aria-live="polite"
        className="fixed bottom-0 right-0 z-90 p-4 w-full max-w-3xl"
      >
        <div className="grid gap-6 sm:flex items-center p-6 pb-4 sm:p-6 bg-pageBG text-pageText">
          <div className="flex-1 sm:pr-8 text-center sm:text-left">
            <p className="text-sm leading-normal">
              {message.split('\n').map((text: string, index: number) => (
                <Fragment key={index}>
                  {text}
                  {message.split('\n')[index + 1] && <br />}
                </Fragment>
              ))}
            </p>
          </div>

          <div className="flex justify-center flex-wrap items-center gap-x-8 gap-y-4">
            {link && (
              <CustomLink
                link={{
                  page: link,
                }}
              >
                {strings.buttonLearnMore}
              </CustomLink>
            )}

            <Button
              onClick={() => onAcceptCookies()}
              variant={ButtonVariant.FILLED}
            >
              {strings.buttonAccept}
            </Button>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  )
}

export default CookieBar
