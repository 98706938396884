import cx from 'classnames'
import { motion, AnimatePresence } from 'motion/react'
import {
  type BaseSyntheticEvent,
  type FormEvent,
  useContext,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'

import { type SanityNewsletterBlock } from '@data/sanity/queries/types/blocks'
import { AnalyticsEventName } from '@lib/analytics'
import { AnalyticsContext } from '@lib/analytics-context'
import { fadeAnimation } from '@lib/animate'
import { LanguageContext } from '@lib/language-context'
import { addEmailToNewsletterList } from '@lib/services'
import { StringsContext } from '@lib/strings-context'

import Alert from '@components/alert'
import Button, {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
} from '@components/buttons/button'
import Checkbox from '@components/checkbox'
import ComplexPortableText from '@components/complex-portable-text'
import InputField from '@components/input-field'
import SimplePortableText from '@components/simple-portable-text'

type NewsletterProps = Omit<SanityNewsletterBlock, '_key' | '_type'> & {
  id: string
  className?: string
  isMinimal?: boolean
}

interface NewsletterFormValues {
  fullname: string
  email: string
  acceptTerms?: boolean
}

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

const Newsletter = ({
  id,
  service,
  listId,
  terms,
  submit,
  successMsg,
  errorMsg,
  className,
  isMinimal,
}: NewsletterProps) => {
  const strings = useContext(StringsContext)
  const { locale } = useContext(LanguageContext)
  const { triggerAnalyticsEvent } = useContext(AnalyticsContext)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)

  const { handleSubmit, register, watch, reset } =
    useForm<NewsletterFormValues>()
  const hasAgreed = terms ? watch('acceptTerms') : true
  const email = watch('email')
  const fullnameRegister = register('fullname')
  const emailRegister = register('email', {
    required: strings.emailMissing,
    pattern: {
      value: emailRegex,
      message: strings.emailInvalid,
    },
  })
  const acceptTermsRegister = register('acceptTerms')

  const resetForm = (event: FormEvent) => {
    event.preventDefault()

    reset()
    setIsError(false)
    setIsSuccess(false)
    setIsSubmitting(false)
  }

  const onSubmit = async (
    newsletterFormValues: NewsletterFormValues,
    event?: BaseSyntheticEvent
  ) => {
    event?.preventDefault()

    setIsError(false)
    setIsSuccess(false)

    // Set an error if there is no list supplied
    if (!listId) {
      setIsError(true)
      return
    }

    // Stop if accepting of terms is required
    if (!hasAgreed && terms) {
      return
    }

    setIsSubmitting(true)

    try {
      await addEmailToNewsletterList(
        locale,
        service,
        listId,
        newsletterFormValues.email,
        newsletterFormValues.fullname
      )
      triggerAnalyticsEvent(AnalyticsEventName.NewsletterSignUp)
      setIsSuccess(true)
    } catch (error) {
      console.log(error)
      setIsError(true)
    }

    setIsSubmitting(false)
  }

  const isDisabled = isSubmitting || !hasAgreed || !emailRegex.test(email)

  if (!service) {
    return null
  }

  return (
    <form className={cx(className)} onSubmit={handleSubmit(onSubmit)}>
      <AnimatePresence mode="wait">
        {!isError && !isSuccess && (
          <motion.div
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnimation}
          >
            <input
              type="text"
              autoComplete="off"
              className="hidden"
              aria-hidden="true"
              onChange={fullnameRegister.onChange}
              onBlur={fullnameRegister.onBlur}
              ref={fullnameRegister.ref}
              name={fullnameRegister.name}
            />

            <InputField
              id={`newsletter-${id}-email`}
              type="email"
              formRegister={emailRegister}
              placeholder={strings.emailAddress}
              isMinimal={isMinimal}
              className="mb-4"
            >
              {isMinimal && (
                <Button
                  id={`newsletter-${id}-submit`}
                  className="ml-3 text-xs whitespace-nowrap uppercase no-underline"
                  type="submit"
                  disabled={isDisabled}
                >
                  {submit}
                </Button>
              )}
            </InputField>

            {terms && (
              <Checkbox
                id={`newsletter-${id}-acceptTerms`}
                formRegister={acceptTermsRegister}
                className="mb-4"
              >
                <div className="rc rc-checkbox">
                  <SimplePortableText content={terms} />
                </div>
              </Checkbox>
            )}

            {!isMinimal && (
              <Button
                id={`newsletter-${id}-submit`}
                type="submit"
                size={ButtonSize.NORMAL}
                color={ButtonColor.DEFAULT}
                variant={ButtonVariant.FILLED}
                disabled={isDisabled}
                className="w-full"
              >
                {submit}
              </Button>
            )}
          </motion.div>
        )}

        {isSuccess && (
          <motion.div
            key="success"
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnimation}
          >
            <Alert className="rc rc-alert">
              <ComplexPortableText content={successMsg} />
            </Alert>
          </motion.div>
        )}

        {isError && (
          <motion.div
            key="error"
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnimation}
          >
            <Alert
              buttonText={strings.buttonTryAgain}
              onClick={resetForm}
              buttonColor={ButtonColor.DEFAULT}
              className="rc rc-alert"
            >
              <ComplexPortableText content={errorMsg} />
            </Alert>
          </motion.div>
        )}
      </AnimatePresence>
    </form>
  )
}

export default Newsletter
