import { useContext, useMemo } from 'react'

import {
  type SanityFilterGroup,
  type SanitySortOptionType,
} from '@data/sanity/queries/types/cart'
import {
  type SanityCollectionGridCollection,
  type SanityCollectionTreeItem,
  type SanityCombinedListingSettings,
} from '@data/sanity/queries/types/modules'
import { type SanityProductFragment } from '@data/sanity/queries/types/product'
import { compareNumbers, compareStrings, getAllCombinations } from './helpers'
import { type UrlParameter } from './hooks'
import { type Filter } from './product'
import { type OptionValue } from './product-options'
import { getCombinedListingProductOptions } from './product/combined-listing/helpers'
import {
  type QuickFilterOption,
  ProductCollectionQuickFilterContext,
} from './product-collection-quick-filter-context'

export type CollectionProduct = SanityProductFragment & {
  preferredOption?: OptionValue
  variantIdProductSlugMap?: Record<number, string>
}

type ProductWithPreferredOption = SanityProductFragment & {
  preferredOption?: OptionValue
}

type Direction = 'ascending' | 'descending'

/**
 * Returns a method that compares products for sorting by price.
 */
const getCompareProductsByPrice = (direction: Direction) => {
  const sign = direction === 'ascending' ? 1 : -1

  return (product1: SanityProductFragment, product2: SanityProductFragment) =>
    sign * compareNumbers(product1.price, product2.price)
}

/**
 * Returns a method that compares products for sorting by title.
 */
const getCompareProductsByTitle = (direction: Direction) => {
  const sign = direction === 'ascending' ? 1 : -1

  return (product1: SanityProductFragment, product2: SanityProductFragment) =>
    sign * compareStrings(product1.title, product2.title)
}

/**
 * Returns a method that compares products for sorting by creation date.
 */
const getCompareProductsByDate = (direction: Direction) => {
  const sign = direction === 'ascending' ? 1 : -1

  return (product1: SanityProductFragment, product2: SanityProductFragment) =>
    sign * compareStrings(product1._createdAt, product2._createdAt)
}

/**
 * Returns a method that compares products for sorting in order of product IDs.
 */
const getCompareProductsByIds = (productIds: number[]) => {
  const productIndices = productIds.reduce(
    (previousValue, currentValue, index) => ({
      ...previousValue,
      [currentValue]: index + 1,
    }),
    {} as Record<number, number>
  )

  return (product1: SanityProductFragment, product2: SanityProductFragment) => {
    // Sort by product index
    const index1 = productIndices[product1.productID]
    const index2 = productIndices[product2.productID]

    if (typeof index1 !== 'undefined' && typeof index2 !== 'undefined') {
      return compareNumbers(index1, index2)
    }

    if (typeof index1 !== 'undefined') {
      return -1
    }

    if (typeof index2 !== 'undefined') {
      return 1
    }

    return 0
  }
}

/**
 * Returns a method that compares collections for sorting in order of IDs.
 */
const getCompareCollectionsByIds = (collectionIds: string[]) => {
  const collectionIndices = collectionIds.reduce(
    (previousValue, currentValue, index) => ({
      ...previousValue,
      [currentValue]: index + 1,
    }),
    {} as Record<string, number>
  )

  return (
    collection1: SanityCollectionTreeItem,
    collection2: SanityCollectionTreeItem
  ) => {
    const index1 = collectionIndices[collection1._id]
    const index2 = collectionIndices[collection2._id]

    if (typeof index1 !== 'undefined' && typeof index2 !== 'undefined') {
      return compareNumbers(index1, index2)
    }

    if (typeof index1 !== 'undefined') {
      return -1
    }

    if (typeof index2 !== 'undefined') {
      return 1
    }

    return 0
  }
}

/**
 * Product filter hook for collections.
 */
export const useProductFilter = <
  T extends SanityProductFragment = SanityProductFragment
>(
  products: T[],
  filters: Filter[]
) => {
  const { quickFilterSelectedValue } = useContext(
    ProductCollectionQuickFilterContext
  )

  return useMemo(() => {
    let filteredProducts = [...products]

    // Get all combinations of all filter values
    const filterCombinations = getAllCombinations(
      ...filters
        .filter((filter) => filter.values.length > 0)
        .map((filter) => filter.values)
    )

    // Get products filtered by page filters
    filteredProducts = filteredProducts.filter((product) => {
      const productFilterValues =
        product.filters?.map((filter) => filter?.filter?.slug?.current) ?? []

      return filterCombinations.some((values) =>
        values.every((value) => productFilterValues.includes(value))
      )
    })

    // Get products filtered by quick filter
    if (quickFilterSelectedValue !== null) {
      // Filter products that belong to the specified collection
      filteredProducts = filteredProducts.filter((product) =>
        product.unstableCollections?.some(
          (collection) =>
            collection.collection?.slug?.current === quickFilterSelectedValue
        )
      )
    }

    return filteredProducts
  }, [filters, products, quickFilterSelectedValue])
}

/**
 * Product sort hook for collections.
 */
export const useProductSort = <
  T extends SanityProductFragment = SanityProductFragment
>(
  products: T[],
  sortType: SanitySortOptionType | null,
  featuredProductIds?: number[]
) => {
  return useMemo(() => {
    const sortedProducts: CollectionProduct[] = [...products]

    switch (sortType) {
      case 'priceAsc': {
        const compareProductsByPriceAscending =
          getCompareProductsByPrice('ascending')
        sortedProducts.sort(compareProductsByPriceAscending)

        break
      }

      case 'priceDesc': {
        const compareProductsByPriceDescending =
          getCompareProductsByPrice('descending')
        sortedProducts.sort(compareProductsByPriceDescending)

        break
      }

      case 'alphaAsc': {
        const compareProductsByTitleAscending =
          getCompareProductsByTitle('ascending')
        sortedProducts.sort(compareProductsByTitleAscending)

        break
      }

      case 'alphaDesc': {
        const compareProductsByTitleDescending =
          getCompareProductsByTitle('descending')
        sortedProducts.sort(compareProductsByTitleDescending)

        break
      }

      case 'dateAsc': {
        const compareProductsByDateAscending =
          getCompareProductsByDate('ascending')
        sortedProducts.sort(compareProductsByDateAscending)

        break
      }

      case 'dateDesc': {
        const compareProductsByDateDescending =
          getCompareProductsByDate('descending')
        sortedProducts.sort(compareProductsByDateDescending)

        break
      }

      case 'featured': {
        const compareProductsByDateDescending =
          getCompareProductsByDate('descending')
        sortedProducts.sort(compareProductsByDateDescending)

        const compareProductsByFeaturedIds = getCompareProductsByIds(
          featuredProductIds ?? []
        )
        sortedProducts.sort(compareProductsByFeaturedIds)

        break
      }
    }

    return sortedProducts
  }, [featuredProductIds, products, sortType])
}

/**
 * Gets sort parameter value from URL parameters.
 */
export const getSortParameterValue = (activeParameters: UrlParameter[]) => {
  const sortParameter = activeParameters.find(
    (activeParameter) => activeParameter.name === 'sort'
  )
  const value = sortParameter?.value

  if (!value || Array.isArray(value)) {
    return null
  }

  return value as SanitySortOptionType
}

/**
 * Returns a method that gets filter from a URL parameter.
 */
export const getFilterFromUrlParameter = (
  filterGroups: SanityFilterGroup[]
) => {
  return (activeParameter: UrlParameter) => {
    const activeParameterValues =
      activeParameter.value && Array.isArray(activeParameter.value)
        ? activeParameter.value
        : activeParameter.value?.split(',') ?? []

    const filterGroup = filterGroups.find(
      (filterGroup) => filterGroup.slug.current === activeParameter.name
    )
    const optionSlugs =
      filterGroup?.options?.map((option) => option.slug.current) ?? []
    const valueSet = new Set(
      activeParameterValues.filter(
        (value) => Boolean(value) && optionSlugs.includes(value)
      )
    )
    const values = [...valueSet]

    // Check if only one value is selected in this filter group and it contains a linked collection
    let productIds: number[] | undefined

    if (values.length === 1) {
      const option = filterGroup?.options.find(
        (option) => option.slug.current === values[0]
      )

      if (option?.collection?.featuredProductIds) {
        productIds = option?.collection?.featuredProductIds
      }
    }

    const filter: Filter = {
      name: activeParameter.name,
      values,
      productIds,
    }

    return filter
  }
}

/**
 * Returns a method that gets updated URL parameter from given new parameters.
 */
export const getUpdatedParameterMapper = (newParameters: UrlParameter[]) => {
  return (parameter: UrlParameter): UrlParameter => {
    const matchedParameter = newParameters.find(
      (newParameter) => newParameter.name === parameter.name
    )

    if (!matchedParameter) {
      return parameter
    }

    return {
      ...parameter,
      value: matchedParameter?.value ?? null,
    }
  }
}

/**
 * Merges filter slugs.
 */
export const mergeFilterSlugs = (products: SanityProductFragment[]) => {
  const slugs = new Set()

  products.forEach((product) => {
    if (product.filters) {
      product.filters.forEach((filter) => {
        const slug = filter?.filter?.slug?.current

        if (slug) {
          slugs.add(slug)
        }
      })
    }
  })

  return Array.from(slugs)
}

/**
 * Gets filtered products and product options which have a discount.
 */
export const getOnlyDiscountedProducts = (products: CollectionProduct[]) => {
  const discountedProducts: CollectionProduct[] = []

  products.forEach((product) => {
    // Filter variants by compare price
    const variantsWithDiscount =
      product.variants?.filter((variant) => variant.comparePrice > 0) ?? []

    // Find option key-value pairs with discount
    const forOptionsWithDiscount: string[] = []
    variantsWithDiscount.forEach((variant) => {
      variant.options.forEach((option) => {
        const forOption = `${option.name}:${option.value}`

        if (!forOptionsWithDiscount.includes(forOption)) {
          forOptionsWithDiscount.push(forOption)
        }
      })
    })

    // Find gallery items, listing items, options and options settings by options which have a discount
    const galleryPhotosWithDiscount = product.galleryPhotos?.filter(
      (galleryPhoto) =>
        !galleryPhoto.forOption ||
        forOptionsWithDiscount.includes(galleryPhoto.forOption)
    )
    const listingPhotosWithDiscount = product.listingPhotos?.filter(
      (listingPhoto) =>
        !listingPhoto.forOption ||
        forOptionsWithDiscount.includes(listingPhoto.forOption)
    )
    const optionSettingsWithDiscount = product.optionSettings?.filter(
      (optionSetting) =>
        !optionSetting.forOption ||
        forOptionsWithDiscount.includes(optionSetting.forOption)
    )
    const optionsWithDiscount = product.options
      .map((option) => ({
        ...option,
        values: option.values.filter((value) => {
          const forOption = `${option.name}:${value}`

          return forOptionsWithDiscount.includes(forOption)
        }),
      }))
      .filter((option) => option.values.length > 0)

    if (variantsWithDiscount.length === 0) {
      return
    }

    const newProduct: CollectionProduct = {
      ...product,
      galleryPhotos: galleryPhotosWithDiscount,
      listingPhotos: listingPhotosWithDiscount,
      options: optionsWithDiscount,
      optionSettings: optionSettingsWithDiscount,
      variants: variantsWithDiscount,
    }
    discountedProducts.push(newProduct)
  })

  return discountedProducts
}

/**
 * Gets expanded products where each product option that has gallery is a separate product.
 */
const getExpandedProducts = (products: SanityProductFragment[]) => {
  const expandedProducts: ProductWithPreferredOption[] = []

  products.forEach((product) => {
    // Create a product for each gallery item
    product.galleryPhotos?.forEach((gallery) => {
      // Find product variant from gallery item settings
      const variant = product.variants?.find((variant) => {
        const variantForOptions = variant.options.map(
          (option) => `${option.name}:${option.value}`
        )

        return (
          !!gallery.forOption && variantForOptions.includes(gallery.forOption)
        )
      })

      const newProduct: ProductWithPreferredOption = {
        ...product,
        // Update price and compare price
        price: variant?.price ?? product.price,
        comparePrice: variant?.comparePrice ?? product.comparePrice,
        // Keep filters for the current option and for all options
        filters: product.filters?.filter(
          (filter) =>
            !filter.forOption || filter.forOption === gallery.forOption
        ),
      }

      if (gallery.forOption) {
        // Set preferred option to show it on the product card
        newProduct.preferredOption = {
          name: gallery.forOption.split(':')[0],
          value: gallery.forOption.split(':')[1],
        }
      }

      expandedProducts.push(newProduct)
    })
  })

  return expandedProducts
}

/**
 * Creates mapping between product IDs and combined listing IDs.
 */
const getProductCombinedListingIdMap = (
  products: CollectionProduct[],
  combinedListings?: SanityCombinedListingSettings[]
) => {
  const productCombinedListingIdMap: Record<string, string> = {}

  products.forEach((product) => {
    const combinedListing = combinedListings?.find((combinedListing) =>
      combinedListing.productIds?.some((productId) => product._id === productId)
    )

    if (!combinedListing) {
      return
    }

    productCombinedListingIdMap[product._id] = combinedListing._id
  })

  return productCombinedListingIdMap
}

/**
 * Creates mapping between combined listing IDs and product IDs.
 */
const getCombinedListingProductIdMap = (
  products: CollectionProduct[],
  combinedListings?: SanityCombinedListingSettings[]
) => {
  const combinedListingProductIdMap: Record<string, string[]> = {}

  products.forEach((product) => {
    const combinedListing = combinedListings?.find((combinedListing) =>
      combinedListing.productIds?.some((productId) => product._id === productId)
    )

    if (!combinedListing) {
      return
    }

    if (!combinedListingProductIdMap[combinedListing._id]) {
      combinedListingProductIdMap[combinedListing._id] = []
    }

    combinedListingProductIdMap[combinedListing._id].push(product._id)
  })

  return combinedListingProductIdMap
}

/**
 * Creates compacted products from combined listing products.
 */
const getCombinedListingCompactedProductMap = (
  combinedListingProductIdMap: Record<string, string[]>,
  products: CollectionProduct[],
  combinedListings?: SanityCombinedListingSettings[]
) => {
  const combinedListingCompactedProductMap: Record<string, CollectionProduct> =
    {}

  Object.entries(combinedListingProductIdMap).forEach(
    ([combinedListingId, productIds]) => {
      const combinedListing = combinedListings?.find(
        (combinedListing) => combinedListing._id === combinedListingId
      )
      const combinedListingProducts = productIds
        .map((productId) =>
          products.find((product) => product._id === productId)
        )
        .filter(Boolean) as CollectionProduct[]

      if (!combinedListing || combinedListingProducts.length === 0) {
        return
      }

      const galleryPhotos = combinedListingProducts.flatMap(
        (product) => product.galleryPhotos ?? []
      )
      const listingPhotos = combinedListingProducts.flatMap(
        (product) => product.listingPhotos ?? []
      )
      const optionSettings = combinedListingProducts.flatMap(
        (product) => product.optionSettings ?? []
      )
      const variants = combinedListingProducts.flatMap(
        (product) => product.variants ?? []
      )
      const options = getCombinedListingProductOptions(combinedListingProducts)

      // Create mapping of variant IDs to products slugs
      const variantIdProductSlugMap: Record<number, string> = {}
      combinedListingProducts.forEach((product) => {
        product.variants?.forEach((variant) => {
          variantIdProductSlugMap[variant.variantID] = product.slug.current
        })
      })

      const newProduct: CollectionProduct = {
        ...combinedListingProducts[0],
        title: combinedListing.title,
        galleryPhotos,
        listingPhotos,
        optionSettings,
        variants,
        options,
        variantIdProductSlugMap,
      }
      combinedListingCompactedProductMap[combinedListingId] = newProduct
    }
  )

  return combinedListingCompactedProductMap
}

/**
 * Gets compacted products where each product belonging to a combined listing is merged into a single product.
 */
const getCompactedProducts = (
  products: CollectionProduct[],
  combinedListings?: SanityCombinedListingSettings[]
) => {
  const productCombinedListingIdMap = getProductCombinedListingIdMap(
    products,
    combinedListings
  )
  const combinedListingProductIdMap = getCombinedListingProductIdMap(
    products,
    combinedListings
  )
  const combinedListingCompactedProductMap =
    getCombinedListingCompactedProductMap(
      combinedListingProductIdMap,
      products,
      combinedListings
    )

  // Replace combined listing products with compacted products
  const compactedProducts: CollectionProduct[] = []
  products.forEach((product) => {
    const combinedListingId = productCombinedListingIdMap[product._id]

    if (!combinedListingId) {
      compactedProducts.push(product)
      return
    }

    // Skip combined listing products after the 1st product since the 1st product is replaced with a compacted product
    const productIds = combinedListingProductIdMap[combinedListingId]

    if (productIds[0] !== product._id) {
      return
    }

    compactedProducts.push(
      combinedListingCompactedProductMap[combinedListingId]
    )
  })

  return compactedProducts
}

/**
 * Sorts collection product list.
 */
const sortCollectionProducts = (
  products: SanityProductFragment[],
  featuredProductIds: number[]
) => {
  const sortedProducts: CollectionProduct[] = [...products]

  if (featuredProductIds && featuredProductIds.length > 0) {
    const compareProductsByFeaturedIds =
      getCompareProductsByIds(featuredProductIds)
    sortedProducts.sort(compareProductsByFeaturedIds)
  }

  return sortedProducts
}

/**
 * Gets collection product list from combined listing settings and other options.
 */
export const getCollectionProducts = (
  products: SanityProductFragment[],
  featuredProductIds: number[],
  combinedListings?: SanityCombinedListingSettings[],
  expandProducts?: boolean,
  onlyDiscounts?: boolean
) => {
  let collectionProducts: CollectionProduct[] = [...products]

  collectionProducts = sortCollectionProducts(
    collectionProducts,
    featuredProductIds
  )

  if (onlyDiscounts) {
    collectionProducts = getOnlyDiscountedProducts(collectionProducts)
  }

  collectionProducts = getCompactedProducts(
    collectionProducts,
    combinedListings
  )

  if (expandProducts) {
    collectionProducts = getExpandedProducts(collectionProducts)
  }

  return collectionProducts
}

/**
 * Gets quick filter values from collection items.
 */
const getCollectionQuickFilterValues = (
  filteredCollections: SanityCollectionTreeItem[],
  featuredCollectionIds?: string[]
) => {
  const compareCollectionsByFeaturedIds = getCompareCollectionsByIds(
    featuredCollectionIds ?? []
  )
  filteredCollections.sort(compareCollectionsByFeaturedIds)

  const quickFilterOptions = filteredCollections
    .map((collection) => {
      if (!collection.slug || !collection.title) {
        return
      }

      const featuredProductIds = collection.products
        ?.flatMap((product) => [
          product.productID,
          ...(product.childProductIds ?? []),
        ])
        ?.filter(Boolean) as number[] | undefined

      const quickFilterOption: QuickFilterOption = {
        value: collection.slug,
        title: collection.title,
        featuredProductIds,
      }

      return quickFilterOption
    })
    .filter(Boolean) as QuickFilterOption[]

  return quickFilterOptions
}

/**
 * Gets quick filter values for shop all page.
 */
export const getShopPageQuickFilterValues = (
  collections: SanityCollectionTreeItem[],
  featuredCollectionIds?: string[]
) => {
  // Take collections that are featured
  const filteredCollections = featuredCollectionIds
    ? collections.filter((collection) =>
        featuredCollectionIds.includes(collection._id)
      )
    : []

  return getCollectionQuickFilterValues(
    filteredCollections,
    featuredCollectionIds
  )
}

/**
 * Gets quick filter values for collection page.
 */
export const getCollectionPageQuickFilterValues = (
  collections: SanityCollectionTreeItem[],
  currentCollection: SanityCollectionGridCollection,
  featuredCollectionIds?: string[]
) => {
  // Take collections that are a child collection to current collection
  const filteredCollections = collections.filter((collection) =>
    collection.parentCollectionIds?.includes(currentCollection._id)
  )

  return getCollectionQuickFilterValues(
    filteredCollections,
    featuredCollectionIds
  )
}
