import axios, { type AxiosHeaderValue, type AxiosResponse } from 'axios'

import { type Locale } from './language'

export interface NewsletterJoinPayload {
  listId: string
  name?: string
  email: string
}

export interface NewsletterJoinResponse {
  error?: string
}

export interface KlaviyoWaitlistJoinPayload {
  variantId: number
  email: string
  name?: string
}

export interface KlaviyoWaitlistJoinResponse {
  error?: string
}

type WaitlistJoinResponse = KlaviyoWaitlistJoinResponse

const getApiUrl = (service: string, action: string) =>
  `/api/${service.toLowerCase()}/${action}`

const getApiHeaders = (locale: Locale): Record<string, AxiosHeaderValue> => ({
  'Content-Type': 'application/json',
  'X-Locale': locale,
})

const callApi = async <T, P>(
  url: string,
  payload: P,
  headers: Record<string, AxiosHeaderValue>
) =>
  await axios.post<T, AxiosResponse<T>, string>(url, JSON.stringify(payload), {
    headers,
  })

/**
 * Calls newsletter signup API page.
 */
export const addEmailToNewsletterList = async (
  locale: Locale,
  service: string,
  listId: string,
  email: string,
  name?: string
) => {
  const url = getApiUrl(service, 'newsletter-join')
  const payload = {
    listId,
    email,
    name,
  }
  const headers = getApiHeaders(locale)
  await callApi<NewsletterJoinResponse, NewsletterJoinPayload>(
    url,
    payload,
    headers
  )
}

/**
 * Calls waitlist signup API page.
 */
export const addEmailToWaitlist = async (
  locale: Locale,
  service: string,
  variantId: number,
  email: string,
  name?: string
) => {
  const url = getApiUrl(service, 'waitlist-join')
  const payload =
    service === 'klaviyo'
      ? {
          variantId,
          email,
          name,
        }
      : null
  const headers = getApiHeaders(locale)
  await callApi<WaitlistJoinResponse, KlaviyoWaitlistJoinPayload | null>(
    url,
    payload,
    headers
  )
}
