import cx from 'classnames'
import { motion } from 'motion/react'
import { useContext, useEffect, useRef, useMemo } from 'react'
import { useResizeObserver } from 'usehooks-ts'

import { type SanityMenuFeaturedLinkFragment } from '@data/sanity/queries/types/site'
import { swipeAnimation } from '@lib/animate'
import { SiteContext } from '@lib/site-context'

import FeaturedItems from './featured-items'
import Menu from './menu'

interface MegaNavigationDropdownProps {
  dropdown: SanityMenuFeaturedLinkFragment
  hasFocus: boolean
  setHasFocus: (hasFocus: boolean) => void
  setActiveDropdownHeight: (activeDropdownHeight: number) => void
}

const MegaNavigationDropdown = ({
  dropdown,
  hasFocus,
  setHasFocus,
  setActiveDropdownHeight,
}: MegaNavigationDropdownProps) => {
  const { megaNavigation, toggleMegaNavigation } = useContext(SiteContext)

  const dropdownRef = useRef<HTMLDivElement>(null)
  const dropdownRectangle = useResizeObserver({
    ref: dropdownRef,
  })

  const isDropdownActive = useMemo(
    () => megaNavigation.isOpen && megaNavigation.activeId === dropdown._key,
    [dropdown._key, megaNavigation.activeId, megaNavigation.isOpen]
  )

  useEffect(() => {
    if (isDropdownActive && typeof dropdownRectangle.height !== 'undefined') {
      setActiveDropdownHeight(dropdownRectangle.height)
    }
  }, [dropdownRectangle.height, isDropdownActive, setActiveDropdownHeight])

  return (
    <div
      ref={dropdownRef}
      id={`meganav-${dropdown._key}`}
      className={cx(
        'absolute top-0 inset-x-0 z-10 -mt-px overflow-hidden transition-visibility duration-200',
        {
          'delay-[0s] pointer-events-auto': isDropdownActive,
          'invisible delay-[.8s] pointer-events-none': !isDropdownActive,
        }
      )}
    >
      <motion.div
        initial="hide"
        animate={isDropdownActive ? 'show' : 'hide'}
        onAnimationComplete={(definition) => setHasFocus(definition === 'show')}
        variants={swipeAnimation}
        className="pl-3 flex relative pb-6 will-change-transform"
      >
        <Menu
          items={dropdown.dropdownItems}
          hasFocus={hasFocus && isDropdownActive}
          onClick={() => toggleMegaNavigation(false)}
          isMegaNavMenu
          className="w-1/3 pt-4"
        />

        {dropdown.featured && dropdown.featured.length > 0 && (
          <FeaturedItems
            className="w-2/3"
            items={dropdown.featured}
            onClick={() => toggleMegaNavigation(false)}
          />
        )}
      </motion.div>
    </div>
  )
}

export default MegaNavigationDropdown
